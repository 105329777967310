<ng-container *ngIf="setting">
  <ng-container *ngIf="!configure; else config">
    <p translate>cookie.description</p>
  </ng-container>
  <div class="button-grid">
    <a class="button main-bg" (click)="acceptAll()" translate>cookie.accept_all</a>
    <a class="button light" *ngIf="!configure" (click)="configure = true" translate>
      cookie.configure
    </a>
    <a class="button light" *ngIf="configure" (click)="save()" translate>cookie.accept</a>
  </div>
</ng-container>

<ng-template #config>
  <div class="checkboxgroup" *ngFor="let cookie of types">
    <div class="checkbox">
      <input
        type="checkbox"
        [name]="cookie"
        [id]="cookie"
        [(ngModel)]="setting[cookie]"
        [disabled]="cookie === 'technical'"
        class="option-input checkbox"
      />
      <label [for]="cookie" translate>
        {{ 'cookie.types.' + cookie | translate }}
        <i class="main-text fas fa-info-circle" (click)="toggle(cookie)"></i>
      </label>
    </div>
    <div class="info" *ngIf="info[cookie]">
      <ng-container
        *ngTemplateOutlet="{ technical: technical, analytics: analytics }[cookie]"
      ></ng-container>
    </div>
  </div>
</ng-template>

<ng-template #technical>
  <h4>1st Party</h4>
  <span
    *ngFor="
      let typ of [
        'swVersion',
        'current_version',
        'current_guest',
        'current_business',
        '_secure__ls__metadata'
      ]
    "
  >
    <b>straiv.io:</b>
    {{ typ }} |
    <b>{{ 'cookie.validity' | translate }}:</b>
    {{ days(365) }}
    <i>straiv GmbH, Industriestraße 23, 70565 Stuttgart, Germany</i>
  </span>
</ng-template>
<ng-template #analytics>
  <h4>3rd Party</h4>
  <span>
    <b>google.com:</b>
    Maps |
    <b>{{ 'cookie.validity' | translate }}:</b>
    {{ days(100) }}
    <i>
      Google Ireland Limited, Google Building Gordon House, 4 Barrow St, Dublin, D04 E5W5, Ireland
    </i>
  </span>
  <span>
    <b>bugsnag.com:</b>
    Error Tracking |
    <b>{{ 'cookie.validity' | translate }}:</b>
    {{ 'cookie.session' | translate }}
    <i>Bugsnag, 110 Sutter St, Suite 1000, CA 94104 San Francisco, United States</i>
  </span>
  <span>
    <b>eu.posthog.com:</b>
    Product Analytics |
    <b>{{ 'cookie.validity' | translate }}:</b>
    {{ 'cookie.session' | translate }}
    <i>PostHog Inc, 2261 Market St #4008, CA 94114 San Francisco, United States</i>
  </span>
  <span>
    <b>eu.datadog.com:</b>
    Product Analytics |
    <b>{{ 'cookie.validity' | translate }}:</b>
    {{ 'cookie.session' | translate }}
    <i>Datadog, Inc., 620 8th Avenue, 45th Floor, NY 10018 New York, United States</i>
  </span>
</ng-template>
