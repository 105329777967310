<footer
  class="footer-container"
  *ngIf="
    !cicoService.addressChange &&
    !(cicoService.disableBack && cicoService.disableNext) &&
    !cicoService.infoScreen
  "
>
  <div class="btn-grid">
    <straiv-button
      *ngIf="!cicoService.hideBack"
      name="back-button"
      variant="outline"
      [leftLabel]="'misc.back' | translate"
      [disabled]="cicoService.disableBack"
      (buttonClick)="onPreviousClick()"
    ></straiv-button>

    <straiv-button
      *ngIf="!showOverlay && !cicoService.hideNext"
      name="back-button"
      [leftLabel]="confirmName | translate: { number: cicoService.totalGrossService }"
      right-icon="fas fa-chevron-right"
      [disabled]="cicoService.disableNext"
      (buttonClick)="onNextClick()"
    ></straiv-button>
  </div>
</footer>
