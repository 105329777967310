import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { Globals } from 'base';
import { PmsCiCoService } from 'cico_service';
import { Address } from 'models/address';
import { Field } from 'models/field';
import { GenericCheckIn } from 'models/pms/generic_check_in';
import { GenericData } from 'models/pms/generic_data';
import { PmsGuest } from 'models/pms/pms_guest';
import { AddressType, PmsModType, TransportationType, TravelPurpose } from 'pms_enums';
import { Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { EventAggregatorService } from 'services/events/event-aggregator.service';
import { EventConstants } from 'global_enums';
import { WebsocketService } from 'services/websocket/websocket.service';
import { GuestSubSteps } from 'models/pms/stepper';
import { PmsReservation } from 'models/pms/pms_reservation';

@Component({
  selector: 'app-pms-guest',
  templateUrl: './guest.component.html',
  styleUrls: ['./guest.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class PmsGuestComponent implements OnInit, OnDestroy {
  @Input() guest: PmsGuest;
  @Input() index: number;
  @Input() kind: string = '';
  @Input() field: Field;
  @Input() travelPurposeField: Field;
  @Input() locale: string = 'default';

  subscriptions = new Subscription();
  search: string;
  name = Math.random()
    .toString(36)
    .replace(/[^a-z]+/g, '')
    .substr(0, 5);
  data: GenericData;
  primaryGuest: boolean;
  fields: any;
  fieldsLoaded: boolean;
  phoneCountry: string;
  useSavedAddress = false;
  primaryFormSubmited: boolean;
  privateFormSubmited: boolean;
  identificationFormSubmited: boolean;
  transportation: boolean;
  guestIndex: any;
  genders: any[];
  settings: any;
  authRequired: string = '';
  isShowIdnowSection: boolean;
  idnow: boolean;
  deviatingBillingAdr: boolean;
  confirmEmailErrorMessage: string;
  toggleCityTax: boolean;
  travelPurposeLock: boolean;
  carLicenceDisabled: boolean;
  hasConfirmEmail: boolean = false;

  constructor(
    public globals: Globals,
    public cicoService: PmsCiCoService,
    private readonly eventService: EventAggregatorService,
    private _cdr: ChangeDetectorRef,
    private wsService: WebsocketService,
  ) {}

  ngOnInit() {
    // When idnow is activated and the user clicks back, show the idnow section.
    this.subscriptions.add(
      this.eventService.getEvent(EventConstants.toShowIdNowWhenUserClickBack).subscribe(() => {
        // set nationality as not selected
        this.cicoService.showNationalityStep = true;

        if (this.idnow) {
          // Generate the header based on the current step.
          this.cicoService.generateSectionHeader(true, PmsModType.ci);
          this.data.authChosen = false;
          this.isShowIdnowSection = true;
          this.cicoService.setShowFooter(false);
        }
      }),
    );

    // When a form is submitted, set the 'submitted' flag to true. this is for showing the validation satate of the input.
    // TODO: This is a temporary solution, this code will be remvoed in new desgin of the form.
    // TODO: Combine it with the form
    this.subscriptions.add(
      this.eventService.getEvent(EventConstants.afterSubmitForm).subscribe(() => {
        switch (this.cicoService.subStepForGuest) {
          case GuestSubSteps.primaryGuest:
            this.primaryFormSubmited = true;
            break;
          case GuestSubSteps.privateAddress:
            this.privateFormSubmited = true;
            break;
          case GuestSubSteps.identificationGuest:
            this.identificationFormSubmited = true;
            break;
          case GuestSubSteps.otherGuest:
            this.primaryFormSubmited = true;
            this.privateFormSubmited = true;
            this.identificationFormSubmited = true;
            break;
        }
      }),
    );

    this.cicoService.data.pipe(filter(Boolean), take(1)).subscribe((data: GenericData) => {
      this.data = data;
      this.setGenders();
      this.toggleCityTax = this.data.module.settings.city_tax_toggle;
      this.travelPurposeLock =
        this.data.module.settings.travel_purpose_lock &&
        (data.incident.reservation.travel_purpose?.length || 0) > 0;
      this.transportation = ['ch'].includes(this.data.business.address.country);
      this.primaryGuest = this.guest.group === GuestSubSteps.primaryGuest;
      // If the current step is a sub-step of primary step, hide the back button, only when sub step is primary_guest.
      if (this.cicoService.subStepForGuest === GuestSubSteps.primaryGuest) {
        this.cicoService.hideBackButton(this.primaryGuest);
      }

      if (!this.transportation && this.cicoService.field_for('car_licence')) {
        (<GenericCheckIn>this.data.incident).setTransportation();
      }

      this.setFields();
      this.checkDocuments();
      this.initialCountry();
      this.setAddressName();
      this.setTheTaxForBusinessTrip();

      this.setTransportationType(
        data.incident.reservation.car_licence
          ? TransportationType.PRIVATE
          : TransportationType.PUBLIC,
      );
      this.subscriptions.add(
        this.cicoService.fieldsUpdated.pipe(filter(Boolean)).subscribe(() => {
          if (this.primaryGuest) {
            this.field = this.cicoService.field_for('primary_guest');
            this.setFields();
            this.cicoService.setShowFooter(true);
            this._cdr.detectChanges();
          }
        }),
      );
    });

    if (this.primaryGuest) {
      this.checkDeviatingBillingAdress();
      this.checkIdnow();
    }

    this.settings = this.setSettings();
    this.authRequired = this.isAuthRequired();

    this._cdr.detectChanges();
  }

  private setTheTaxForBusinessTrip() {
    const reservation: PmsReservation = this.data.incident.reservation;
    if (this.isBusinessTrip(reservation)) {
      reservation.travel_purpose = TravelPurpose.business_trip;
      this.checkCityTax();
    }
  }

  private isBusinessTrip(reservation: PmsReservation): boolean {
    return (
      this.travelPurposeField &&
      !reservation?.travel_purpose &&
      reservation?.address?.address_type === AddressType.company
    );
  }

  setFields() {
    this.fields = {};
    [
      'first_name',
      'last_name',
      'gender',
      'nationality',
      'date_of_birth',
      'place_of_birth',
      'email',
      'phone',
      'private_address',
      'profession',
      'invoice_address',
    ].forEach((name) => {
      this.fields[name] = this.field_for(name);
    });

    [
      ['car_licence', null],
      ['marketing', null],
    ].forEach((entry) => {
      this.fields[entry[1] ? entry[1] : entry[0]] = this.travel_info(entry[0], entry[1]);
    });
    this.hasConfirmEmail = this.fields.email?.setting('double_check') || false;
    this.fieldsLoaded = true;
    this._cdr.detectChanges();
  }

  checkFields() {
    if (this.primaryGuest) {
      this.cicoService.updatedFields();
    }
  }

  onSelectedNationality(event: any) {
    if (event?.detail?.selectedValue) {
      this.guest.nationality = event.detail.selectedValue.value;
    }
    this.checkDocuments();
  }

  private checkFieldsNationality() {
    if (this.data.module.settings.check_changes) {
      this.fieldsLoaded = false;
      this.cicoService.setShowFooter(false);
      this.cicoService.fetchFieldsFromApi();
    } else {
      this.cicoService.setShowFooter(true);
    }
  }

  checkDocuments() {
    this.cicoService.setPassportVisa();
  }

  checkDeviatingBillingAdress() {
    this.deviatingBillingAdr = this.fields['invoice_address']?.active;
    const address = this.data.incident.reservation.address;

    if (this.cicoService.deviatingBillingAddress === undefined) {
      this.cicoService.deviatingBillingAddress =
        address && !address.isEmpty() && address.internal_id !== this.guest.internal_id
          ? true
          : null;
    }
  }

  domestic_guest(): boolean {
    return this.data?.incident?.reservation?.primary_guest?.domestic(this.data?.business);
  }

  setting_for(name) {
    return this.settings?.find((f) => f.identifier === name)?.value;
  }

  private setSettings(): any {
    return this.cicoService
      .field_for('primary_guest')
      ?.subField('passport')
      ?.subField('passport_image')?.settings;
  }

  private isAuthRequired(): string {
    return this.setting_for(
      this.domestic_guest() ? 'validation_domestic' : 'validation_foreigners',
    );
  }

  setGenders() {
    this.genders = (this.data.module.settings.genders || ['male', 'female', 'other']).map(
      (gender) => {
        return { name: this.globals.translate(`booking_data.gender.${gender}`), value: gender };
      },
    );
  }

  changeGenderMethod(event: any): void {
    if (event?.value) {
      this.guest.gender = event.value;
    }
  }

  initialCountry() {
    if (this.fields.phone) {
      if (!this.guest.phone?.length) {
        this.phoneCountry = (
          this.guest.address?.country ||
          this.guest.nationality ||
          this.globals.country
        )?.toLowerCase();
      } else {
        this.phoneCountry = 'defined';
      }
    }
  }

  field_for(id, sub = null): Field {
    const result = this.field?.fields?.find((field) => field.identifier === id);
    return sub == null ? result : result?.fields?.find((field) => field.identifier === sub);
  }

  travel_info(id, sub = null): Field {
    const result = this.data?.module?.field(id) || this.cicoService.field_for(id);
    return sub == null ? result : result?.fields?.find((field) => field.identifier === sub);
  }

  applyAddress() {
    this.useSavedAddress = !this.useSavedAddress;
    if (this.useSavedAddress) {
      this.guest.address = new Address(this.copyData());
    } else {
      this.guest.address = Object.assign({});
    }
  }

  validateConfirm(event: any) {
    if (this.cicoService.hasValidationMessages(event)) {
      this.confirmEmailErrorMessage = event.detail.validationMessages.includes('confirm_email')
        ? 'validation.email_not_match'
        : 'misc.invalid_date';
    }
  }

  reasonForTravelToggle(event: any) {
    this.data.incident.reservation.travel_purpose =
      event?.detail === TravelPurpose.private_trip
        ? TravelPurpose.private_trip
        : TravelPurpose.business_trip;
    this.checkCityTax();
  }

  onChangeMarketingCallback(event: any) {
    this.data.incident.reservation.marketing = event.detail;
  }

  onChangeBillingAdCallback(event: any) {
    this.cicoService.deviatingBillingAddress = event.detail;
    this.deviatingAddrToggleChange();
  }

  setTransportationType(value: string) {
    this.data.incident.transportation = value;
    this.carLicenceDisabled = value === TransportationType.PUBLIC;
    if (this.carLicenceDisabled) {
      this.data.incident.reservation.car_licence = null;
    }
  }

  disableCarLicence(event: any) {
    this.setTransportationType(
      event?.target?.value ? TransportationType.PRIVATE : TransportationType.PUBLIC,
    );
  }

  checkCityTax() {
    if (this.toggleCityTax) {
      const remove = this.data.incident.reservation.travel_purpose === TravelPurpose.business_trip;
      this.subscriptions.add(
        this.cicoService.pmsService.setCityTax(remove).subscribe(() => {
          this.cicoService.folioUpdateSubj.next(true);
        }),
      );
    }
  }

  setAddressName() {
    if (this.primaryGuest) {
      this.cicoService.setAddressName();
    }
  }

  copyData(): any {
    const address = this.data.incident.reservation.primary_guest.address;
    return {
      first_name: this.guest.first_name,
      last_name: this.guest.last_name,
      address: address.address,
      zipcode: address.zipcode,
      city: address.city,
      state: address.state,
      country: address.country,
    };
  }

  checkIdnow(done = false) {
    this.cicoService.showNationalityStep = true;
    if (done) {
      this.isShowIdnowSection = false;
      this.cicoService.setPassportVisa();
      this.cicoService.setShowFooter(false);
      this.cicoService.hideBackButton(true);
    } else {
      this.idnow = this.data.incident.reservation.idnow();
      this.isShowIdnowSection =
        this.wsService.socketConnected && !this.data.authChosen && this.idnow;
    }
  }

  dismissed() {
    this.isShowIdnowSection = false;
    // when nationality is set
    this.cicoService.showNationalityStep = false;
    this.cicoService.hideBackButton(false);

    if (this.cicoService.withOutPMSIsActive) {
      this.cicoService.hideNextButton(false);
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.checkDocuments();
    this.checkFieldsNationality();
    // ...........................
    // record the user actions
    this.globals.viewSubj.next(`${this.data.module.type}-${this.cicoService.subStepForGuest}`);
    // Generate the header based on the current step.
    this.cicoService.generateSectionHeader(this.isShowIdnowSection, PmsModType.ci);
  }

  selectNationality(event: any) {
    if (event?.detail?.selectedValue) {
      this.guest.nationality = event.detail.selectedValue.value;
      this.cicoService.setPassportVisa();
    }
  }

  private deviatingAddrToggleChange() {
    if (!this.cicoService.deviatingBillingAddress) {
      // Remove the address if it exists
      const index = this.cicoService.addresses.findIndex(
        (value) => value === this.data.incident.reservation.address,
      );
      if (index !== -1) {
        this.cicoService.addresses.splice(index, 1);
      }
      // Set the default address to a new Address with null value
      this.data.incident.reservation.address.clear();

      // Update each folio's address based on the folio number
      this.data.incident.reservation.folios.forEach((folio) => {
        folio.address = this.data.folioAddresses.find(
          (adr) => adr.number === folio.number,
        )?.address;
      });
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
