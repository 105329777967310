<ng-container *ngIf="data">
  <ng-container *ngIf="!pending">
    <div class="content-container animation-out" *ngIf="!authUrl?.length && !authFailed">
      <div *ngIf="nationalityField" class="nationality-selection">
        <straiv-select
          inputId="nationality"
          [items]="data.countriesNat | json"
          [value]="guest.nationality"
          [labelHeader]="nationalityField.name"
          searchable="{{ true }}"
          searchText="{{ 'misc.search' | translate }}"
          [placeholder]="nationalityField.name"
          (selectedEvent)="selectNationality($event)"
          [errorMessage]="'validation.nationality' | translate"
        ></straiv-select>
      </div>

      <ng-container *ngIf="verificationOptions?.length; else no_validation">
        <div class="verification-selection animation-in" *ngIf="guest.nationality">
          <straiv-section-header
            *ngIf="verificationOptions?.length > 1"
            [heading]="'service.check_in.authentication.description' | translate"
          ></straiv-section-header>
          <div class="actions-grid">
            <straiv-actioncard
              *ngFor="let option of verificationOptions"
              [text]="option.text | translate"
              [icon]="'fas fa-' + option.icon"
              (cardClick)="idfMethod(option.method)"
            ></straiv-actioncard>
          </div>
        </div>
      </ng-container>

      <div *ngIf="authFailed" class="animation-in">
        <div class="desc">
          <div
            class="instructions not-first-instruction"
            [innerHTML]="ui_messages.auth_failed?.content"
          ></div>
        </div>
        <div class="button-row">
          <button type="button" class="btn-filled" (click)="retryAuth()" translate>
            service.folios.retry
          </button>
          <button
            type="button"
            class="btn-outline"
            (click)="manual()"
            *ngIf="authRequired === 'optional'"
            translate
          >
            service.check_in.authentication.manual
          </button>
        </div>
      </div>
    </div>

    <div class="iframe_container" *ngIf="authUrl?.length && !authFailed">
      <iframe
        [src]="authUrl | safe"
        id="external_link"
        allow="camera *; microphone *"
        allowfullscreen=""
      ></iframe>
    </div>

    <div *ngIf="authFailed" class="animation-in">
      <div class="desc">
        <div
          class="instructions not-first-instruction"
          [innerHTML]="ui_messages.auth_failed?.content"
        ></div>
      </div>
      <div class="button-row">
        <button type="button" class="btn-filled" (click)="retryAuth()" translate>
          service.folios.retry
        </button>
        <button
          type="button"
          class="btn-outline"
          (click)="manual()"
          *ngIf="authRequired === 'optional'"
          translate
        >
          service.check_in.authentication.manual
        </button>
      </div>
    </div>
  </ng-container>

  <app-loader [text]="'service.check_in.authentication.pending'" *ngIf="pending"></app-loader>
</ng-container>
<ng-template #no_validation>
  <straiv-button
    [disabled]="!guest.nationality || cicoService.disableNext"
    [leftLabel]="'misc.next' | translate"
    right-icon="fa-solid fa-chevron-right fa-lg"
    variant="flat"
    (buttonClick)="manual()"
  ></straiv-button>
</ng-template>
