export enum UserActionType {
  back = 1,
  next,
  cancel,
}

export enum FellowType {
  guest = 1,
  counter,
}

export enum PassportSetting {
  all = 'all',
  domestic = 'domestic',
  foreigner = 'foreigner',
  none = 'none',
}

export enum PaymentProvider {
  adyen = 'adyen',
  concardis = 'concardis',
  datatrans = 'datatrans',
  worldline = 'worldline',
  ibelsa_pay = 'ibelsa_pay',
}

export enum PaymentProcess {
  pre_auth = 'pre_auth',
  payment = 'payment',
  choice = 'choice',
  optional_choice = 'optional_choice',
  nothing = 'nothing',
}

export enum Step {
  guests = 'guests',
  reservation = 'reservation',
  invoice = 'invoice',
  confirm = 'confirm',
}

export enum LostKeyStep {
  bookingData = 1,
  lostOrDuplicate,
  encoder,
  pinCodes,
}

export enum PmsProcess {
  default = 'default',
  check_in = 'check_in',
  check_out = 'check_out',
  door = 'door',
  booking = 'booking',
  reservation = 'reservation',
}

export enum OverlayAction {
  cancel = 1,
  close,
  skip,
  closeSkipCi,
  skipCi,
  reallySure,
  reload,
  addToFolios,
  start,
}

export enum OverlayType {
  cancel,
  confirm,
  skip,
  skipCi,
  component,
  reallySure,
  addToFolios,
  help,
  noCard,
  finish,
  reset,
}

export enum ConfirmName {
  next = 'continue',
  add_product = 'add_product',
  send = 'send',
  skip = 'skip',
  ci = 'check_in',
  co = 'check_out',
}

export enum PmsModType {
  ci = 'pms_check_in',
  co = 'pms_check_out',
  wizard = 'pms_wizard',
  journey = 'pms_journey',
  door = 'pms_door',
  reservation = 'pms_reservation',
  service = 'pms_service',
  payment = 'pms_payment',
}

export enum ResState {
  created = 'created',
  confirmed = 'confirmed',
  pre_checked_in = 'pre_checked_in',
  checked_in = 'checked_in',
  checked_out = 'checked_out',
  canceled = 'canceled',
  no_show = 'no_show',
}

export enum DoorType {
  api = 'api',
  pin_codes = 'pin_codes',
  cards = 'cards',
}

export enum StepStates {
  disabled = 'disabled',
  active = 'active',
  done = 'done',
}

export enum AddressType {
  personal = 'personal',
  company = 'company',
}

export enum TravelPurpose {
  private_trip = 'private_trip',
  business_trip = 'business_trip',
  other = 'other',
}

export enum FormFieldKeys {
  primaryGuest = 'primary_guest',
  adultFellows = 'adult_fellows',
  childrenFellows = 'children_fellows',
}

export enum CusProductTypes {
  breakfast = 'breakfast',
  roomCategory = 'room_category',
}

export enum FolioType {
  creditCard = 'credit_card',
  terminal = 'terminal',
  payment = 'payment',
}

export enum ProcessStep {
  SEND_TO_PMS = 'send_to_pms',
  INFORM_PMS = 'inform_pms',
  INVOICE_ADDRESS = 'invoice_address',
  ASSIGN_ROOM = 'assign_room',
  CHECK_ROOM = 'check_room',
  CHECK_IN = 'check_in',
}

export enum PreventionStep {
  INVALID_TIME = 'invalid_time',
  WRONG_DEVICE = 'wrong_device',
  NOT_LOADED = 'not_loaded',
  NOT_PAID = 'not_paid',
  CANT_PERFORM = 'cant_perform',
  NOT_AUTHORIZED = 'not_authorized',
  INVALID_ANSWERS = 'invalid_answers',
}

export enum TransportationType {
  PRIVATE = 'private',
  PUBLIC = 'public',
}
