import { TransportationType } from 'pms_enums';
import { FieldValue } from '../field_value';
import { PmsReservation } from './pms_reservation';

export class GenericCheckIn {
  reservation: PmsReservation;
  field_values: FieldValue[] = [];
  skip_card: boolean;
  prevented: boolean;
  prevention: string;
  transportation: string;
  steps: any = {};
  checks: any = {};

  constructor(object: any) {
    this.reservation = new PmsReservation(object.reservation || object);
    this.skip_card = object.skip_card;
    this.prevented = object.prevented;
    this.prevention = object.prevention;
    this.transportation = object.transportation || object.reservation.transportation;
    this.checks = object.checks || {};
    for (const field of object.field_values || []) {
      this.field_values.push(new FieldValue(field));
    }
  }

  getStorageKey(): string {
    return 'ci_incident_' + this.reservation?.reservation_id;
  }

  setTransportation(): void {
    this.transportation = this.reservation.car_licence
      ? TransportationType.PRIVATE
      : TransportationType.PUBLIC;
  }
}
